html,body,#root{
    height: 100%;
    width: 100%;
}

@media (max-width: 335px) {
    html {
        width: fit-content;
    }
}

.hw-100{
    height: 100% !important;
    width: 100% !important;
}

/* flex properties  */
.flex{
    display: flex !important;
}
.flex-row{
    display: flex !important;
    flex-direction: row !important;
}
.flex-column{
    display: flex !important;
    flex-direction: column !important;
}
.flex-row-reverse{
    display: flex;
    flex-direction: row-reverse;
}
.flex-wrap{
    display: flex !important;
    flex-wrap: wrap !important;
}
.ovf-hide{
    overflow: hidden;
}

/* padding and margin*/
.p-0 {
    padding: 0px !important;
}
.p-5 {
padding: 5px !important;
}
.p-15 {
padding: 15px !important;
}
.p-r-0 {
padding-right: 0px !important;
}
.p-r-8 {
padding-right: 8px !important;
}
.p-r-7 {
    padding-right: 7px !important;
    }
.p-r-34 {
padding-right: 34px !important;
}
.p-r-45{
    padding-right: 35px !important;
}
.p-r-3-per{
    padding-right: 3% !important;
}
.p-r-8-per {
    padding-right: 8% !important;
}
.p-r-035 {
    padding-right: 0.35rem !important;
}

.p-b-0 {
padding-bottom: 0px !important;
}
.p-b-5 {
    padding-bottom: 5px !important;
}
.p-b-10 {
padding-bottom: 10px !important;
}
.p-b-17{
    padding-bottom: 17px !important;
}
.p-b-auto{
    padding-bottom: auto !important;
}

.p-t-4{
    padding-top: 4px !important
}
.p-t-20 {
padding-top: 20px !important;
}
.p-t-10 {
padding-top: 10px !important;
}
.p-t-2 {
padding-top: 2rem !important;
}
.p-t-auto{
    padding-top: auto !important;
}
.p-l-30 {
    padding-left: 30px !important;
}
.p-l-53{
    padding-left: 53px !important;
}
.p-l-64 {
    padding-left: 64px !important;
}
.p-l-3-per{
    padding-left: 3% !important;
}

.p-l-7 {
    padding-left: 7px !important;
}
.p-l-8-per {
    padding-left: 8% !important;
}
.p-l-035 {
    padding-left: 0.35rem !important;
}

.p-0-5 {
padding: 0px 5px !important;
}
.m-0 {
margin: 0px !important;
}
.m-10 {
margin: 10px !important;
}
.m-5 {
margin: 5px !important;
}
.m-t--20{
    margin-top: -20px !important;
}
.m-t-0 {
margin-top: 0px !important;
}
.m-t-1 {
margin-top: 1px !important;
}
.m-t-5 {
margin-top: 5px !important;
}
.m-t-8 {
margin-top: 8px !important;
}
.m-t-10 {
margin-top: 10px !important;
}
.m-t-15 {
margin-top: 15px !important;
}
.m-t-14 {
margin-top: 14px !important;
}
.m-t-25 {
margin-top: 25px !important;
}
.m-t-30 {
margin-top: 30px !important;
}
.m-t-128 {
    margin-top: 128px !important;
}
.m-t-15 {
    margin-top: 1.5rem !important;
}
.m-r4 {
margin-right: -4px !important;
}
.m-r-0 {
margin-right: 0px !important;
}
.m-r-02r {
margin-right: 0.2rem !important;
}
.m-r-2r {
margin-right: 2rem !important;
}
.m-r-8 {
margin-right: 8px !important;
}
.m-r-10 {
margin-right: 10px !important;
}
.m-r-15{
    margin-right: 15px !important;
}
.m-r-34{
    margin-right: 34px !important;
}
.m-r-45 {
    margin-right: 45px !important;
}
.m-r-60 {
margin-right: 60px !important;
}
.m-r-25 {
margin-right: 25px !important;
}
.m-b-0 {
margin-bottom: 0px !important;
}
.m-r-20 {
margin-right: 20px !important;
}
.m-r-4r {
    margin-right: 4rem !important;
}

.m-b-1 {
    margin-bottom: 1rem !important;
}
.m-b-8 {
margin-bottom: 8px !important;
}
.m-b-10 {
margin-bottom: 10px !important;
}
.m-b-15 {
margin-bottom: 15px !important;
}
.m-b-15r {
    margin-bottom: 2rem;
}
.m-b--40 {
margin-bottom: -40px !important;
}
.m-b-20 {
    margin-bottom: 20px !important;
}
.m-b-100{
    margin-bottom: 100px !important;
}
.m-b-170 {
margin-bottom: 170px !important;
}

.m-l-auto{
    margin-left: auto !important;
}
.m-l-02r {
margin-left: 0.2rem !important;
}
.m-l-0 {
margin-left: 0px !important;
}
.m-l-2r {
margin-left: 2rem !important;
}
.m-l-6 {
margin-left: 6px  !important;
}
.m-l-8 {
margin-left: 8px  !important;
}
.m-l-10 {
margin-left: 10px  !important;
}
.m-l-18 {
    margin-left: 18px !important;
}
.m-l-21{
    margin-left: 21px !important;
}
.m-l-24 {
margin-left: 24px !important;
}
.m-l-30 {
    margin-left: 30px !important;
    }
.m-l-40 {
margin-left: 40px !important;
}
.m-l--14{
    margin-left: -14px !important;
}

.p-lr-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

/* widths */
.min-w-0{
    min-width: 0px !important;
}
.min-w-35{
    min-width: 35px !important;
}
.min-w-43{
    min-width: 43px !important;
}
.w-20-per {
    width: 20% !important;
}
.w-18-per {
    width: 18%;
}
.w-50-per {
    width: 50% !important;
}
.w-100-per {
   width: 100% !important;
}
.w-24{
    width: 24px !important;
}
.w-32{
    width: 32px !important;
}
.w-35 {
    width: 35px !important;
}
.w-50{
    width: 50px !important;
}
.w-100 {
    width: 100px !important;
}
.w-120 {
    width: 120px !important;
}
.w-135 {
    width: 135px !important;
}
.w-150 {
    width: 150px !important;
}
.w-180{
    width: 180px !important;
}
.w-200 {
    width: 200px !important;
}
.w-250 {
    width: 250px !important;
}
.w-320{
    width: 320px !important;
}
.w-450{
    width: 450px !important;
}
.w-470{
    width: 470px !important;
}
.w-750{
    width: 750px !important;
}
.w-auto{
    width: auto !important;
}

/* heights */
.h-100-per{
    height: 100% !important;
}
.h-14-per{
    height: 14% !important;
}
.h-15{
    height: 15px !important;
}
.h-32{
    height: 32px !important;
}
.h-100{
    height: 100px !important;
}
.h-120{
    height: 120px !important;
}
.h-400 {
    height: 400px !important;
}
.h-450 {
    height: 450px !important;
}
.min-h-100{
    min-height: 100px !important;
}
.min-height-50{
    min-height: 50px;
}


/* Colors */
.black{
    color: #181617 !important;
}

.black-bg{
    background-color: #181617 !important;
}

.green{
    color: #01E69C !important;
}

.green-bg{
    background-color: #01E69C !important;
}
.white{
    color: #FCFCFC !important;
}
.white-bg{
    background-color: #FCFCFC !important;
}

.light-white-bg {
    background-color: #FFFFFF !important;
}

.light-gray{
    color: #F4F4F4 !important ;
}
.light-gray-bg{
    background-color: #F4F4F4 !important ;
}
.gray-bg {
    background: #E5E5E5;
}

.gray {
    color: #E5E5E5 !important;
}
.transparent{
    background-color: transparent !important;
}
.inherit-bg {
    background: inherit !important;
}

.purple{
    color: #8E54E9;
}
.purple-bg{
    background-color: #8E54E9;
}
.light-blue{
    color: #51AED6
}
.light-blue-bg{
    background-color: #51AED6;
}
.dark-green{
    color: #46967C;
}
.dark-green-bg{
    background-color: #46967C;
}

.transparent{
    background-color: transparent !important;
}

.opacity-25{
    opacity: 0.25 !important;
}
.opacity-4{
    opacity: 0.4 !important;
}
.opacity-5{
    opacity: 0.5 !important;
}

.jc-center{
    justify-content: center !important;
}
.jc-space-btw{
    justify-content: space-between !important;
}
.jc-space-evenly{
    justify-content: space-evenly !important;
}
.jc-space-around{
    justify-content: space-around !important;
}
.jc-end{
    justify-content: flex-end !important;
}
.ai-center{
    align-items: center !important;
}
.align-self-start{
    align-self: flex-start !important;
}
.align-self-center{
    align-self: center;
}
.align-self-end{
    align-self: flex-end !important;
}

.flex-grow-1{
    flex-grow: 1;
}
.flex-grow-2{
    flex-grow: 2;
}
.flex-grow-3{
    flex-grow: 3;
}
.flex-grow-4{
    flex-grow: 4;
}

.MuiButton-root{
    font-family: Gilroy;
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-transform: none !important;
    border-radius: 44px !important;
}
.text-align-center{
    text-align: center;
}

/* borders */

.border-b-black{
    border-bottom:solid  rgb(0,0,0,0.1) !important;
}
.border-w-1{
    border-width: 1px !important;
}
.border-w-2{
    border-width: 2px !important;
}
.border-r-9{
    border-radius: 9px !important;
}
.border-r-30 {
    border-radius: 30px !important;
}
.border-r-50per{
    border-radius: 50% !important;
}
.border-b-0 {
    border-bottom: 0px none !important;
}
.border-b-1-black {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.border-r-50per-green{
    border : 2px solid #01E69C !important;
    border-radius: 50% !important;
}

.z-index-1{
    z-index: 1;
}


/* Font sizes */
.MuiTypography-h4{
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-size: 30px !important;
    line-height: 37px !important;
}
.MuiTypography-h5{
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-size: 25px !important;
    line-height: 31px !important;
}
.MuiTypography-h6{
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-size: 20px !important;
    line-height: 25px !important;
}

.MuiTypography-h9{
    font-size: 22px !important;
    line-height: 29.4px !important;
}
.MuiTypography-subtitle1{
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-size: 17px !important;
    line-height: 21px !important;
}
.MuiTypography-subtitle2{
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-size: 15px !important;
    line-height: 14px !important;
}
.MuiTypography-body1{
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-size: 13px !important;
    line-height: 16px !important;
}
.MuiTypography-body2{
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-size: 12px !important;
    line-height: 15px !important;
}
.MuiTypography-caption{
    font-family: 'Gilroy-Regular', sans-serif !important;
    font-size: 11px !important;
    line-height: 13px !important;
}
/* .MuiTextField-root{
    color: #F4F4F4 !important ;
    background-color: #F4F4F4 !important ;
} */


.MuiInputBase-root{
    height: 30px !important ;
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-weight: 800 !important;
    font-size: 18px !important;
    color: #231F20;
}
/* .MuiSelect-icon{
    display: none !important;
} */
.MuiInputBase-multiline{
    height: 70px !important ;
    font-family: 'Gilroy-Regular', sans-serif !important;
    font-weight: 300 !important;
    font-size: 14px !important;
}
.MuiFormLabel-root{
    font-family: 'Gilroy-Bold', sans-serif !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    color: #231F20 !important;
    opacity: 0.5 !important;
}

.MuiDialogContent-root{
    padding: 16px !important;
}

.MuiListItemIcon-root{
    min-width: 0px !important; 
}
.MuiListItem-root{
    display: flex !important;
    justify-content: center !important;
    padding-top: 20px !important;
}

.MuiDialog-paper{
    border-radius: 30px !important;
}
/* .Mui-focused {
    color: #000000 !important;
} */

.font-w-800{
    font-weight: 800 !important;
}
.font-color-black {
    color: #231F20 !important;
}

.box-shadow {
    box-shadow: 3px 4px 11px -3px rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
    cursor: pointer;
}
fieldset{
    border-color: transparent !important;
}

.position-relative {
    position: relative;
}

@media (max-width: 450px) {
    .MuiTypography-h4{
        font-size: 22px !important;
    }
    .MuiTypography-h5{
        font-size: 15px !important;
    }
    .MuiTypography-h6{
        font-size: 15px !important;
    }
    .MuiTypography-subtitle2{
        font-size: 12px !important;
    }
    .MuiTypography-subtitle1{
        font-size: 12px !important;
    }
    .m-b-15r {
        margin-bottom: 0.8rem;
    }
}

@media (min-width: 2000px) {
    .MuiTypography-h4{
        font-size: 50px !important;
    }
    .MuiTypography-h5{
        font-size: 35px !important;
    }
    .MuiTypography-h6{
        font-size: 35px !important;
    }
    .MuiTypography-subtitle2{
        font-size: 30px !important;
    }
    .MuiTypography-subtitle1{
        font-size: 30px !important;
    }
}

div::-webkit-scrollbar {
    width: 0.5rem;
}

div::-webkit-scrollbar-track {
    background: #f4f4f4;
}

div::-webkit-scrollbar-thumb {
    background-color: #00000023; 
    border-radius: 20px; 
    border: 3px #e2e3e2; 
}


.MuiTreeItem-content.Mui-selected{
    background-color: #F4F4F4 !important;
}